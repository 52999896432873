import axios from 'axios';

// const baseURL = 'http://localhost:2411';
// const baseURL2 = 'http://localhost:2411';


const baseURL = 'https://6tj49pt8me.execute-api.ap-south-1.amazonaws.com/dev'
const baseURL2 = "https://gfp6wzlori.execute-api.ap-south-1.amazonaws.com/dev"

function get(url) {
    return axios.get(baseURL + url);
}

function post(url, obj) {
    return axios.post(baseURL + url, obj);
}

function put(url, obj) {
    return axios.put(baseURL + url, obj);
}

function deleteApi(url, obj) {
    return axios.delete(baseURL + url, obj);
}

function get2(url) {
    return axios.get(baseURL2 + url);
}

function post2(url, obj) {
    return axios.post(baseURL2 + url, obj);
}

function put2(url, obj) {
    return axios.put(baseURL2 + url, obj);
}

function deleteApi2(url, obj) {
    return axios.delete(baseURL2 + url, obj);
}

export default {
    get,
    post,
    put,
    deleteApi,
    get2,
    post2,
    put2,
    deleteApi2,
}